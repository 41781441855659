
.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}


.details{
  text-align: center;
  align-items: center;
  justify-content: center;
  align-content: center;
  margin-left: auto;
  margin-right: auto;
}

.list{
  text-align: center;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.gallery {
    display: block; min-height: 1px; width: 100%; border: 1px solid rgb(221, 221, 221); overflow: auto;
}

.wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
}
#sidebar{
    min-width: 250px;
    max-width: 250px;
    height: 100%;
    max-height: 100%;
    /* margin-left: -250px; */
    background: #31353D;
    color: #fff;
    transition: all 0.3s;
    position: fixed;
    top: 0;
    overflow-y: auto;
    z-index: 999;    
}
#sidebar .sidebar-home{
    padding: 5px;
    background: #0f582b;
    color: #fff;
    text-align: center;
}

#sidebar .sidebar-header{
    padding: 10px;
    background: #6d7fcc;
}

.sidebar-wrapper ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

#sidebar ul.components {
    padding: 20px 0;
}
.sidebar-wrapper .sidebar-menu ul li a, .sidebar-footer > a {
    color: #818896;
}
.sidebar-wrapper .sidebar-menu ul li a.active, .sidebar-footer > a.active {
    color: #7386D5;
    background: #fff;
}
.sidebar-wrapper .sidebar-menu ul li a i {
    margin-right: 10px;
    font-size: 12px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 4px;
}
.sidebar-wrapper .sidebar-menu ul li a i {
    background: #3a3f48;
}
.sidebar-wrapper .sidebar-menu ul li a.active i {
    background: #ddd;
}
.sidebar-wrapper .sidebar-menu ul li:hover > a {
    color: #b8bfce;
}

#sidebar.active {
    /* margin-left: -250px; */
    margin-left: 0;
}

#sidebar ul p {
    color: #fff;
    padding: 10px;
}

#sidebar ul li a {
    padding: 5px;
    display: block;
}
.sidebar-footer {
    position: absolute;
    width: 100%;
    bottom: 0;
    display: flex;
    color: #818896;
  }
  
.sidebar-footer > a {
    flex-grow: 1;
    text-align: center;
    height: 30px;
    line-height: 30px;
    position: relative;
 }

.body{
    font-family: 'Poppins', sans-serif;
    background: #fafafa;
}

.p {
    font-family: 'Poppins', sans-serif;
    font-size: 1.1em;
    font-weight: 300;
    line-height: 1.7em;
    color: #999;
}

a, a:hover, a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
}

#sidebar ul li a:hover {
    /* color: #7386D5; */
    /* background: #fff; */
}

#sidebar ul li.active > a, a[aria-expanded="true"] {
    color: #fff;
    background: #6d7fcc;
}

ul ul a {
    font-size: 0.9em !important;
    padding-left: 30px !important;
    background: #6d7fcc;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

table {
    width: 100%;
}

.content {
    width: 100%;
    margin-left: 250px;
}
h1{
  text-align:center;
}

 .panel{ color:#0099ff; font-size:14px; margin-bottom:10px; line-height:24px;}
.main-div {
  background: #ffffff none repeat scroll 0 0;
  border-radius: 3px;
  
  margin: 10px auto 30px;
  text-align: center;
}

.login-form{
    align-items: center;
  width:450px;
  text-align: center;
  align-content: center;
    justify-content: center;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  
}
.base-form{
    min-width: 300px;
}
.conatainer{
    align-content: center;
    justify-content: center;
    align-items: center
}
.box{
  border-style: solid;
  width:auto;
  padding-top: 20px;
  padding-right: 35px;
  padding-bottom: 20px;
  padding-left: 35px;
  border: 1px solid black;
  text-align:center;
}
ol.breadcrumb{
    padding:  20px;
    align-content: center;
    justify-content: center;
}
.details .form-group{
    text-align: left;
    align-items: center;
    height: auto;
    
    display: block;  width:350px;   overflow: auto;
    margin-left: auto;
    margin-right: auto;
    
  }
  
 
  .switch {
    position: relative;
    display: inline-block;
    width: 150px;
    height: 50px;
  }
  
  .switch input {display:none;}
  .slider {
    position: absolute;
    cursor: pointer;
    overflow: hidden;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(230, 30, 30);
    -webkit-transition: .4s;
    transition: .4s;
  }
  .slider:before {
    position: absolute;
    z-index: 2;
    content: "";
    height: 50px;
    width: 50px;
    left: 2px;
    bottom: 2px;
    background-color: rgb(245, 245, 245);
         -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.22);
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.22);
    -webkit-transition: .4s;
    transition: all 0.4s ease-in-out;
  }
  .slider:after {
    position: absolute;
    left: 0;
    z-index: 1;
    content: "Open";
      font-size: 20px;
      text-align: left !important;
      line-height: 55px;
    padding-left: 0;
      width: 150px;
      color: #fff;
      height: 50px;
      border-radius: 100px;
      background-color: rgb(78, 21, 233);
      -webkit-transform: translateX(-160px);
      -ms-transform: translateX(-160px);
      transform: translateX(-160px);
      transition: all 0.4s ease-in-out;
  }
  
  input:checked + .slider:after {
    -webkit-transform: translateX(0px);
    -ms-transform: translateX(0px);
    transform: translateX(0px);
    /*width: 235px;*/
    padding-left: 25px;
  }
  
  input:checked + .slider:before {
    background-color: #fff;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(100px);
    -ms-transform: translateX(100px);
    transform: translateX(100px);
  }

  .slider.round {
    border-radius: 50px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  .absolute-no {
      position: absolute;
      left: 0;
      color: white;
      text-align: right !important;
      font-size: 20px;
      width: calc(100% - 25px);
      height: 100px;
      line-height: 50px;
      cursor: pointer;
  }

.fc-marker {
    display: block;
    position: absolute;
    padding-left: 26px;
    transform: translate(-11px, -30px);    
    color: rgb(231,76,60);
    background: url('/assets/marker.svg') no-repeat;
    min-height: 30px;
    padding-bottom: 5px;
}
.fc-marker span{
    font-size: 1.5em;
    width:200px;
}
.rich-text {
    height: 100%;
    width: 100%;
}